export const HELP_TEXT = [
    { section: 'playlist', values: ["Feedback can sometimes feel harsh, or unnecessarily brutal. Remember that it's not just you up against tight deadlines, your Lead or Supervisor, is also feeling the pressure, they don't always have the time to write gentle feedback notes. Don't take it personally, explore their input, and accept that you're all working towards the big picture.",
  "In an ideal world, feedback on your versions will be specific, and developmental. That's not always the case though; so be prepared to ask for more specific feedback if revision notes lack clarity or direction. Do so assertively; \"Thanks for your feedback, I'm keen to get this right - could you give me a little more direction on what you specifically want changed and why?\""] },
    { section: 'todos', values: ["Understandably, as a project or production evolves, so your Tasks screen grows. The more tasks you see on the screen, the more overwhelming it can become. Avoid feelings of overwhelm and anxiety by tackling your task management in bitesize chunks. If the majority of your tasks are stuck in 'WFA' status and you know that the next step of the pipe is desperately waiting for these, then be assertive, and give your reviewer a gentle nudge!"] },
    { section: 'default', values: 
      ["We'll show you lots of hints and tips on how you can perfect your professional skills within each area of the studio pipeline and everyday interactions with colleagues."]
    },
    { section: 'assets', values: 
      ["This shows you all of the assets in the production."]
    },    
    { section: 'team', values: 
      ["Your professional network is key to your success in the industry. It's important to remember that every person you come into contact with will have a wider network of industry professionals who they talk to regularly. Depending on the impression you give, and the perception others have of working with you, they'll either talk favourably - or not - of you. How will you present yourself in a professional, and valuable way which leaves a lasting impression?"]
    },
    { section: 'productions', values :["The Productions list enables a studio to have a strong definition of which projects or productions are live, so users can focus on the projects they are assigned to."]},
    { section: 'profile', values :["Be mindful of your use of language when using Discord. Some language is inappropriate, obviously avoid using swear words or sexual language, but also consider using plain English where possible. There are many 'turns of phrase' in the English language that native speakers use without even realising. For a speaker of another language though, using a term such as 'gotcha' or 'innit' or a longer phrase like \"cutting corners\" will make little sense.",
  "When we communicate, dependent on the platform we are using, we translate that communication in different ways. Verbal communication is the 'text' or the words we say, Vocal communication is the 'way' we say it, and non-verbal communication is everything else besides such as our body language or facial expressions. When we communicate purely through text, the receiver of our message has to rely on only the words said. This is where miscommunication can occur. Before hitting send, just re-read what you've written. Could this be taken in another way?"]}
  ]

